import React from 'react'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Desliza from 'sections/portfolio/Desliza.js'
import Testimonialx3 from 'sections/testimonials/Testimonialx3.js'
import Testimonialx1 from 'sections/testimonials/Testimonialx1.js'
import TestimonialMarketing from 'sections/testimonials/TestimonialMarketing.js'
import TestimonialExpectation from 'sections/testimonials/TestimonialExpectation.js'
import TestimonialGenesis from 'sections/testimonials/TestimonialGenesis.js'
import TestimonialAttributes from 'sections/testimonials/TestimonialAttributes.js'

class Index extends React.Component {

  render() {

    const { site } = this.props.data

    return (
      <div>
        <Helmet>
          <title>{site.meta.title}</title>
          <meta name="description" content={site.meta.description} />
        </Helmet>
        <Layout
          isHome={true}
          sections={['Home', 'Road Map', 'Life Paper', 'About Us']}
        >
           <Desliza />
           <Testimonialx1/>
          
        { /*
           <Testimonialx3 /> 
           <TestimonialMarketing/>
           <TestimonialExpectation/>
           <TestimonialGenesis/>
           <TestimonialAttributes/>
           */}
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeOneQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
  `