import React from 'react'
import { Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import Ttp from 'sections/testimonials/parts/Ttp.js'
import AnimatedHeading from 'components/animated-heading'
import fondo from 'sections/images/fondos/f2.jpg'
import Cookies from 'universal-cookie';
import Idioma from 'sections/idiomas/contents.json'
import file_ingles from 'sections/pdfs/Road_map_ingles.pdf'
import file_espanol from 'sections/pdfs/Road_map_espanol.pdf'
class TestimonialsThree extends React.Component {

    constructor(props) {
      const cookies = new Cookies();
      super(props)
      let title=""
      let url=null;
      let a=cookies.get('idioma')
      if(a=="ingles"){
          title=Idioma.ingles.road_map.titulo;
          url = file_ingles;
      }else{
          title=Idioma.espanol.road_map.titulo;
          url = file_espanol;
      }

      this.state={
        titulo:title,
        urls:url
      }
    }
    render() {
        const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-image: url(${fondo});
            background-size: cover;
            background-repeat: no-repeat;
        `

        const TestimonialContainer = styled.div`
        
        padding: 0px 0;
            @media (max-width: 767px) {
                padding: 5px 5px;
                text-align: justify;
        `
        const Text = styled.p`
        -webkit-text-stroke: 1px black;
    `
    const Submit = styled.button`
        
    height: 50px;
    width: 200px;
    position: relative;
    border: none;
    overflow: hidden;
    transition: .5s;
    &::after {
      position: absolute;
      content:'';
      display: inline-block;
      background: rgba(255, 0, 255, 1);
      background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(195 62 195 / 63%) 25%, rgba(255, 0, 255, 1) 51%, rgba(0, 0, 0, 1) 100%);
      height: 100%;
      width:  140%;
      top: 0;
      left: 0;
      transition: .5s;
    }
    
    &:hover {
      &::after {
        transform: translateX(-20%);
      }
    }
    span {
      font-size: 15px;
      position: relative;
      top: 0;
      -webkit-text-stroke: 0.5px black;
      color: #fff;
      z-index: 10;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
`


        return(
            <Section id="testimonials">
                <TestimonialContainer>
                    <Container>
                    <div className='row py-2'>
                                            <div className='col-md-12  text-center py-5'>

                                            <Submit ><span>
                                            <a type="button" className='text-light' href={this.state.urls} download >DESCARGAR ROAD MAP</a>
                                          </span></Submit>
                                           
                                            </div>
                                        </div>
                     <Text className='h3 text-center text-light'>
                     {this.state.titulo}
                     </Text>
                     
                      <AnimationContainer animation="fadeIn">
                        <Ttp testimonials={this.props.testimonials} quotation_up={this.props.quotation_up} quotation_down={this.props.quotation_down}  />
                      </AnimationContainer>
                    </Container>
                </TestimonialContainer>
            </Section>
        )
    }

 

}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        quotation_up: file(relativePath: {eq: "quotation-up.png"}) {
            childImageSharp {
              fluid(maxWidth: 500) {
                src
              }
            }
          }
          quotation_down: file(relativePath: {eq: "quotation-down.png"}) {
            childImageSharp {
              fluid(maxWidth: 500) {
                src
              }
            }
          }
          testimonials: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(testimonials)/"}}, sort: {fields: [frontmatter___id], order: ASC}) {
            edges {
              content: node {
                frontmatter {
                  id
                  name
                  profession
                  text
                  image {
                    childImageSharp {
                      fluid(maxWidth: 200, maxHeight: 200) {
                        src
                      }
                    }
                  }
                  large {
                    childImageSharp {
                      fluid(maxWidth: 2000, maxHeight: 2000) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
      }      
      `}
      render={({ quotation_up, quotation_down, testimonials }) => <TestimonialsThree quotation_up={quotation_up} quotation_down={quotation_down} testimonials={testimonials.edges} {...props} />}
    />
  )