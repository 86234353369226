import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styled, { keyframes } from 'styled-components'
import BaffleText from 'components/baffle-text'
import Testimonialx from 'sections/testimonials/Testimonialx.js'
import * as images from 'sections/images/images.jsx'
import AnimationContainer from 'components/animation-container'
import Idioma from 'sections/idiomas/contents.json'
import Cookies from 'universal-cookie';
class TestimonialsThreePart extends React.Component {

    constructor(props){
        const cookies = new Cookies();
        super(props)
        let title=""
        let content=""
        let title1=""
        let content1=""
        let title2=""
        let content2=""
        let title3=""
        let content3=""
        let title4=""
        let content4=""
        let title5=""
        let content5=""
        let a=cookies.get('idioma')
        if(a=="ingles"){
            title=Idioma.ingles.road_map.contenido.t_cont_1;
            content=Idioma.ingles.road_map.contenido.c_cont_1;
            title1=Idioma.ingles.road_map.contenido.t_cont_2;
            content1=Idioma.ingles.road_map.contenido.c_cont_2;
            title2=Idioma.ingles.road_map.contenido.t_cont_3;
            content2=Idioma.ingles.road_map.contenido.c_cont_3;
            title3=Idioma.ingles.road_map.contenido.t_cont_4;
            content3=Idioma.ingles.road_map.contenido.c_cont_4;
            title4=Idioma.ingles.road_map.contenido.t_cont_5;
            content4=Idioma.ingles.road_map.contenido.c_cont_5;
            title5=Idioma.ingles.road_map.contenido.t_cont_6;
            content5=Idioma.ingles.road_map.contenido.c_cont_6;
        }else{
            title=Idioma.espanol.road_map.contenido.t_cont_1;
            content=Idioma.espanol.road_map.contenido.c_cont_1;
            title1=Idioma.espanol.road_map.contenido.t_cont_2;
            content1=Idioma.espanol.road_map.contenido.c_cont_2;
            title2=Idioma.espanol.road_map.contenido.t_cont_3;
            content2=Idioma.espanol.road_map.contenido.c_cont_3;
            title3=Idioma.espanol.road_map.contenido.t_cont_4;
            content3=Idioma.espanol.road_map.contenido.c_cont_4;
            title4=Idioma.espanol.road_map.contenido.t_cont_5;
            content4=Idioma.espanol.road_map.contenido.c_cont_5;
            title5=Idioma.espanol.road_map.contenido.t_cont_6;
            content5=Idioma.espanol.road_map.contenido.c_cont_6;
        }

        this.state={
          titulo:title,
          contenido:content,
          titulo1:title1,
          contenido1:content1,
          titulo2:title2,
          contenido2:content2,
          titulo3:title3,
          contenido3:content3,
          titulo4:title4,
          contenido4:content4,
          titulo5:title5,
          contenido5:content5,
          active:0
        }
       
      }
    render() {

        return(
            <Container>
                {this.testimonials()}
            </Container>
        )
    }

    testimonials() {

        const gradientAnimation = keyframes`
            0% {
                background-position: 15% 0%;
            }
            50% {
                background-position: 85% 100%;
            }
            100% {
                background-position: 15% 0%;
            }
        `

        const Gradient = styled.div`
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
            background-size: 300% 300%;
            clip-path: polygon(0% 100%, 10px 100%, 10px 10px, calc(100% - 10px) 10px, calc(100% - 10px) calc(100% - 10px), 10px calc(100% - 10px), 10px 100%, 100% 100%, 100% 0%, 0% 0%);
            animation: ${gradientAnimation} 5s ease-in-out infinite;
        
        `
        const AnimatedShadow = keyframes`
        0%   {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
        10%  {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
        20%  {box-shadow: 0 28px 60px rgba(239, 78, 123, .5);}
        30%  {box-shadow: 0 28px 60px rgba(161, 102, 171, .5);}
        40% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
        50% {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
        60% {box-shadow: 0 28px 60px rgba(7, 179, 155, .5);}
        70% {box-shadow: 0 28px 60px rgba(111, 186, 130, .5);}
        80% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
        90% {box-shadow: 0 28px 60px rgba(16, 152, 173, .5);}
        100% {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
        `
        const TestimonialBox = styled.div`
        animation: ${AnimatedShadow} 10s infinite alternate;
            padding: 50px;
            background-color: #8f8f8fa8;
            min-height: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            @media (max-width:767px) {
                min-height: 700px;
                padding: 10px;
            }
        `

        const ImageBox = styled.div`
            
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            overflow: hidden;
            @media (max-width:767px) {
                min-height: 150px;
                padding: 0;
            }
        `

        const QuotationUp = styled.img`
            position: absolute;
            height: 100px;
            width: 100px;
            top: 15px;
            left: 20px;
            object-fit: contain;
            opacity: .1;
            @media (max-width:767px) {
                height: 50px;
                width: 50px;
            }
        `

        const QuotationDown = styled.img`
            position: absolute;
            height: 100px;
            width: 100px;
            bottom: 15px;
            right: 20px;
            object-fit: contain;
            opacity: .1;
            @media (max-width:767px) {
                height: 50px;
                width: 50px;
            }
        `
        const TestimonialCol = styled(Col)`
            padding: 0;
        `

        const Image = styled.img`
           
            width: 75%;
            object-fit: cover;
            @media (min-width:768px) {
                min-height: 300px;
                max-height: 300px;
            }
            @media (max-width:768px) {
                min-height: 150px;
                max-height: 150px;
            }
        `

        const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `

        const TestimonialRow = styled(Row)`
            animation: ${Fade} 1s forwards;
            margin-top: 50px;
        `
        
        const TestimonialText = styled.div`
            color: #eee;
            line-height: 25px;
            max-width: 80%;
            margin: 0 auto 10px auto;
            text-align: center;
            span {
                font-size: 16px;
                @media (max-width: 500px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        `

        const TestimonialClient = styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
        `
        
        const TestimonialClientInfo = styled.div`
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
        `

        const TestimonialClientName = styled.p`
            color: #eee;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 20px;
        `

        const TestimonialClientProfession = styled.p`
            color: #ccc;
            margin-bottom: 0;
        `

        const Selectors = styled.div`
            display: flex;
            justify-content: center;
            position: relative;
            top: 10px;
            @media (min-width: 1200px) {
                top: 50px;
            }
        `

        const Blockprimary =styled.div`
            @media (min-width:768px) {
                display:none;
            }
           
        `
        const BlockprimaryA =styled.div`
           
            @media (max-width:768px) {
                display:none;
            }
        `
        
        return this.props.testimonials.map((value, index) => {
            if (index === this.state.active) {
                return (
                    <div className='container py-4 '>
                        <Blockprimary >
                        <div className='row'>
                                <div className='col-md-6 text-center py-4'>
                            <Image src={images.images1} class="img-fluid" alt="Responsive image"/>
                                </div>
                                <div className='col-md-6'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 text-center py-4'>
                            <Image src={images.images2} class="img-fluid" alt="Responsive image"/>
                                </div>
                                <div className='col-md-6'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo1}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido1}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 text-center py-4'>
                            <Image src={images.images3} class="img-fluid" alt="Responsive image"/>
                                </div>
                                <div className='col-md-6'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo2}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido2}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 text-center py-4'>
                            <Image src={images.images4} class="img-fluid" alt="Responsive image"/>
                                </div>
                                <div className='col-md-6'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo3}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido3}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 text-center py-4'>
                            <Image src={images.images5} class="img-fluid" alt="Responsive image"/>
                                </div>
                                <div className='col-md-6'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo4}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido4}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 text-center py-4'>
                            <Image src={images.images6} class="img-fluid" alt="Responsive image"/>
                                </div>
                                <div className='col-md-6'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo5}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido5}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                            </div>
                        </Blockprimary>
                        <BlockprimaryA >
                        <div className='row align-items-center'>
                                <div className='col-md-6 text-center py-4'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                                <div className='col-md-6'>
                                    <Image src={images.images1} class="text-center img-fluid" alt="Responsive image"/>
                               
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-md-6 text-center py-4'>
                            <Image src={images.images2} class="img-fluid" alt="Responsive image"/>
                                </div>
                                <div className='col-md-6'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo1}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido1}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-md-6 text-center py-4'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo2}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido2}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                                <div className='col-md-6'>
                                <Image src={images.images3} class="img-fluid" alt="Responsive image"/>
                                
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-md-6 text-center py-4'>
                            <Image src={images.images4} class="img-fluid" alt="Responsive image"/>
                                </div>
                                <div className='col-md-6'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo3}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido3}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-md-6 text-center py-4'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo4}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido4}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                                <div className='col-md-6'>
                                <Image src={images.images5} class="img-fluid" alt="Responsive image"/>
                               
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-md-6 text-center py-4'>
                            <Image src={images.images6} class="img-fluid" alt="Responsive image"/>
                                </div>
                                <div className='col-md-6'>
                                <TestimonialBox>
                                       <AnimationContainer animation="fadeIn">
                                       <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo5}</p>
                                       <p class="h4 text-center text-light">
                                       {this.state.contenido5}
                                       </p>
                                       </AnimationContainer>
                               </TestimonialBox>
                                </div>
                            </div>
                        </BlockprimaryA>
                    </div>
                    
                )
            } else {
                return null
            }
        })
    }
   

    selectorButtons() {
        const Selector = styled.button`
            background-color: #fff;
            height: 15px;
            width: 15px;
            border-radius: 7.5px;
            border: none;
            transition: .5s;
            margin: 0 5px;
            &:hover, &.active {
                background-color: #04e5e5;
            }
            &:focus {
                outline: none;
            }
        `
        return this.props.testimonials.map((value, index) => {
            return (
                <Selector key={index} onClick={() => this.setState({active: index})} className={this.state.active === index ? "active" : ""}/>
            )
        })
    }

}

export default TestimonialsThreePart