import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styled, { keyframes } from 'styled-components'

import MarketingStrategy from 'sections/testimonials/MarketingStrategy.js'
import * as images from 'sections/images/images.jsx'


class TestimonialsThreePart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            active: 0
        }
    }
    render() {

        return(
            <Container>
                {this.testimonials()}
            </Container>
        )
    }

    testimonials() {

    
      


        const TestimonialBox = styled.div`
        
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            @media (max-width:767px) {
                min-height: 400px;
                padding: 0;
            }
        `

        const ImageBox = styled.div`
            
          
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            overflow: hidden;
            @media (max-width:767px) {
                min-height: 400px;
                padding: 0;
            }
        `

       

      
        const TestimonialCol = styled(Col)`
            padding: 0;
        `

       

        const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `

        const TestimonialRow = styled(Row)`
            animation: ${Fade} 1s forwards;
            margin-top: 50px;
        `
        
        


        return this.props.testimonials.map((value, index) => {
            if (index === this.state.active) {
                return (
                    <TestimonialRow key={index} className='py-5'>
                        <TestimonialCol md={1}>
                        </TestimonialCol>
                        <TestimonialCol md={3}>
                            <ImageBox>
                            <img src={images.images3}  class="img-fluid" />
                            </ImageBox>
                        </TestimonialCol>
                        <TestimonialCol md={2}>
                        </TestimonialCol> 
                        <TestimonialCol md={6}>
                            <TestimonialBox>
                        <MarketingStrategy/> 
                           </TestimonialBox>
                        </TestimonialCol>
                    </TestimonialRow>
                )
            } else {
                return null
            }
        })
    }
   
    selectorButtons() {
        const Selector = styled.button`
            background-color: #fff;
            height: 15px;
            width: 15px;
            border-radius: 7.5px;
            border: none;
            transition: .5s;
            margin: 0 5px;
            &:hover, &.active {
                background-color: #04e5e5;
            }
            &:focus {
                outline: none;
            }
        `
        return this.props.testimonials.map((value, index) => {
            return (
                <Selector key={index} onClick={() => this.setState({active: index})} className={this.state.active === index ? "active" : ""}/>
            )
        })
    }

}

export default TestimonialsThreePart