import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import RevealContent from 'components/reveal-content'
import AnimationContainer from 'components/animation-container'
import  { keyframes } from 'styled-components'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Idioma from 'sections/idiomas/contents.json'
import Cookies from 'universal-cookie';

class ExpectationStrategy extends React.Component {

    constructor(props){
        const cookies = new Cookies();
        super(props)
        let title=""
        let content=""
        let a=cookies.get('idioma')
        if(a=="ingles"){
            title=Idioma.ingles.road_map.contenido.t_cont_4;
            content=Idioma.ingles.road_map.contenido.c_cont_4;
        }else{
            title=Idioma.espanol.road_map.contenido.t_cont_4;
            content=Idioma.espanol.road_map.contenido.c_cont_4;
        }

        this.state={
          titulo:title,
          contenido:content
        }
       
      }
    render() {
        const Section = styled.section`
        position: relative;
        overflow: hidden;
        background-color: #00ff0000;
        background-size: cover;
        .heading {
            width: 100%;
            min-height: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            @media (max-width: 767px) {
                min-height: inherit;
                margin-bottom: 50px;
            }
        }
      }
    `

    const TestimonialContainer = styled.div`
        padding: 0px 0;
        width: 100%;
        height:50%;
        background-color: #8f8f8fa8;
        @media (max-width: 767px) {
            padding: 50px 10px;
        }
    `

    const Heading = styled.h1`
        font-size: 150px;
        line-height: 120px;
        font-family: Teko;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        margin-bottom: 0;
        @media (min-width:768px) and (max-width:1399px) {
            font-size: 70px;
            line-height: 70px;
        }
        @media (max-width:767px) {
            font-size: 40px;
            line-height: 40px;
            text-align: center;
        }
    `
    const Color = styled.span`
        color:  #04e5e5;
        font-size: 155px;
        line-height: 120px;
        font-family: Teko;
        text-transform: uppercase;
        letter-spacing: 2px;
        @media (min-width:768px) and (max-width:1399px) {
            font-size: 70px;
            line-height: 70px;
        }
        @media (max-width:767px) {
            font-size: 40px;
            line-height: 40px;
        }
    `
    const AnimatedShadow = keyframes`
    0%   {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
    10%  {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
    20%  {box-shadow: 0 28px 60px rgba(239, 78, 123, .5);}
    30%  {box-shadow: 0 28px 60px rgba(161, 102, 171, .5);}
    40% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
    50% {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
    60% {box-shadow: 0 28px 60px rgba(7, 179, 155, .5);}
    70% {box-shadow: 0 28px 60px rgba(111, 186, 130, .5);}
    80% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
    90% {box-shadow: 0 28px 60px rgba(16, 152, 173, .5);}
    100% {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
`
    const TestimonialBox = styled.div`
    animation: ${AnimatedShadow} 10s infinite alternate;
        padding: 50px;
      
        min-height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        @media (max-width:767px) {
            min-height: 700px;
            padding: 20px;
        }
    `

    const QuotationUp = styled.img`
        position: absolute;
        height: 100px;
        width: 100px;
        top: 5px;
        left: 10px;
        object-fit: contain;
        opacity: .7;
    `

    const QuotationDown = styled.img`
        position: absolute;
        height: 100px;
        width: 100px;
        bottom: 5px;
        right: 10px;
        object-fit: contain;
        opacity: .7;
    `
    const settings = {
        dots: true,
        swipe: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 10000,
        loop: true,
    }
        return(
            <Section id="testimonials">
                <TestimonialContainer>
                    <Container>
                        <Row>

                            
                           
                            <Col md={12}>
                                <TestimonialBox>
                                      
                                <AnimationContainer animation="fadeIn">
                                        <p class=" h3 text-center text-black borde-blanco"  >{this.state.titulo}</p>
                                        <p class="h4 text-justify text-light">
                                        {this.state.contenido}
                                        </p>
                                        </AnimationContainer>
                                       
                                       
                                </TestimonialBox>
                            </Col>
                        </Row>
                    </Container>
                </TestimonialContainer>
            </Section>
        )
    }

    testimonials() {

        const TestimonialItem = styled.div`
        
        `
        const TestimonialText = styled.p`
            color: #333;
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
            text-align: center;
        `

        const TestimonialClient = styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;
        `

        const TestimonialImg = styled.img`
            height: 70px;
            width: 70px;
            border-radius: 40px;
        `
        
        const TestimonialClientInfo = styled.div`
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-left: 20px;
        `

        const TestimonialClientName = styled.p`
            margin-bottom: 0;
            font-weight: 600;
            font-size: 20px;
        `

        const TestimonialClientProfession = styled.p`
            margin-bottom: 0;
        `
        
        return this.props.testimonials.map((value, index) => {
            return (
                <TestimonialItem key={index}>
                    <TestimonialText>
                        {value.content.frontmatter.text}
                    </TestimonialText>
                    <TestimonialClient>
                        <TestimonialImg src={value.content.frontmatter.image.childImageSharp.fluid.src} alt={value.content.frontmatter.name} />
                        <TestimonialClientInfo>
                            <TestimonialClientName>{value.content.frontmatter.name}</TestimonialClientName>
                            <TestimonialClientProfession>{value.content.frontmatter.profession}</TestimonialClientProfession>
                        </TestimonialClientInfo>
                    </TestimonialClient>
                </TestimonialItem>
            )
        })
    }

}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        quotation_up: file(relativePath: {eq: "quotation-up.png"}) {
            childImageSharp {
              fluid(maxWidth: 500) {
                src
              }
            }
          }
          quotation_down: file(relativePath: {eq: "quotation-down.png"}) {
            childImageSharp {
              fluid(maxWidth: 500) {
                src
              }
            }
          }
          testimonials: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(testimonials)/"}}, sort: {fields: [frontmatter___id], order: ASC}) {
            edges {
              content: node {
                frontmatter {
                  id
                  name
                  profession
                  text
                  image {
                    childImageSharp {
                      fluid(maxWidth: 200, maxHeight: 200) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
      }      
      `}
      render={({ quotation_up, quotation_down, testimonials }) => <ExpectationStrategy quotation_up={quotation_up} quotation_down={quotation_down} testimonials={testimonials.edges} {...props} />}
    />
  )